import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import InstagramIcon from "@mui/icons-material/Instagram";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import axios from "axios";
import { useSelector } from "react-redux";
import "../Footer/Footer.css";

const Footer = () => {
  const { category } = useSelector((state) => state.category);
  const navigate = useNavigate();
  const [icon, setIcon] = useState(false);
  const [form, setForm] = useState(null);

  const fetchAddress = async () => {
    try {
      const res = await axios.get("/api/admin/get-address");
      if (res.data.success) {
        setForm(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIcon(true);
      } else {
        setIcon(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function clearSpace(value) {
    return form && value.replace(/\s+/g, "");
  }

  return (
    <React.Fragment>
      <div className="footer-container">
        <div className={`whatspp-icon ${icon && "active"}`}>
          <Link
            target="_blank"
            to={`https://wa.me/${clearSpace(form?.mobile)}`}
          >
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        <div className="row">
          <div className="footerlogo col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h4 className="logo">Atlantis</h4>
            <p className="mt-4 mb-3">
              <b>Address</b>
            </p>
            <div className="footer-contact">
              <FmdGoodIcon className="icon" />
              <span
                dangerouslySetInnerHTML={{ __html: form?.address }}
                style={{ whiteSpace: "pre-wrap" }}
              />
            </div>
            <div className="footer-contact">
              <LocalPhoneIcon className="icon" />
              <span className="my-2">{form?.mobile}</span>
            </div>
            <div className="footer-contact">
              {form?.mobileTwo && (
                <>
                  <LocalPhoneIcon className="icon" />
                  <span className="my-2">{form?.mobileTwo}</span>
                </>
              )}
            </div>
            <div className="footer-contact">
              <EmailIcon className="icon" />
              <span className="my-2">{form?.email}</span>
            </div>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>
              <b>Quick Links</b>
            </h6>
            <ul>
              <li onClick={() => navigate("/")}>Home</li>
              <li onClick={() => navigate("/about")}>About Us</li>
              <li onClick={() => navigate("/contact")}>Contact</li>
            </ul>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>
              <b>Packages</b>
            </h6>
            <ul>
              {category &&
                category?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() =>
                        navigate(
                          `/category/${item?.category.replace(" ", "-")}`
                        )
                      }
                    >
                      {item?.category}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>
              <b>Important Pages</b>
            </h6>
            <ul>
              <li onClick={() => navigate("/terms")}>Terms & Conditions</li>
              <li onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
              </li>
              {/* <li onClick={() => navigate("/refund-policy")}>Refund Policy</li> */}
            </ul>
          </div>
          <div className="quick-links col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>
              <b>Connect here</b>
            </h6>
            <div className="social-media-links">
              <Link
                target="_blank"
                to="https://www.instagram.com/atlantisholidayz"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to="https://www.facebook.com/profile.php?id=100082222682685&mibextid=LQQJ4d "
              >
                <FacebookIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to={`https://wa.me/${clearSpace(form?.mobile)}`}
              >
                <WhatsAppIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:holidaysatlantis@gmail.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>
          <hr />
          <p className="text-center m-0">
            <small>
              All Rights Reserved © 2024 | ATLANTIS |{" "}
              <br className="d-block d-md-none d-lg-none" />
              Website designed & developed by{" "}
              <Link
                target="_blank"
                style={{ color: "#000", fontWeight: "500" }}
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </small>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
